/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "fontsource-bebas-neue"
import "fontsource-source-sans-pro"
import "fontsource-fira-code"

import "./src/styles/scss/main.scss"
